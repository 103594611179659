import React, { useState } from 'react';
import media from 'css-in-js-media';
import styled from 'styled-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { CtaLink } from '../../graphql-fragments/ctaLink';
import ButtonNew from '../ui/ButtonNew';
import { getUrlFromVersatileLink } from '../../utils/utils';
import { Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';

interface HeroProps {
  slides: Array<{
    image: {
      asset: {
        url: string;
        gatsbyImageData: IGatsbyImageData;
      };
    };
    badge: {
      asset: {
        url: string;
      };
    };
    title: string;
    description: string;
    ctaLink?: CtaLink;
  }>;
}

const HeroSection = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: var(--colour-black);
  display: flex;
`;

const SwiperContainer = styled.div`
  position: relative;
  width: 100%;

  .swiper {
    height: 100%;
    ${media('>tablet')} {
    }

    .swiper-slide {
      display: flex;
    }

  }

  .swiper-pagination-container {
    display: flex;
    gap: 14px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: max-content!important;
    z-index: 10;
    padding: 40px 30px;

    ${media('>tablet')} {
      padding: 70px 100px;
    }

    .active-slide {
      font-family: var(--light-title-font-family);
      color: #ffffff;
      text-wrap: nowrap;
      font-size: 16px;
      line-height: 16px;
      font-weight: 300;
      letter-spacing: -2px;
    
      ${media('>desktop')} {
        font-size: 20px;
        line-height: 20px;
      }
    }

    .swiper-pagination-bullets {
      display: flex;
      flex-direction: row;
      gap: 8px;
      justify-content: center;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .swiper-pagination-bullet {
      background: #ffffff;
      opacity: 0.4;
      width: 9px;
      height: 9px;
      margin: 0px !important;
      transition: all 0.3s ease-in-out;
    
      &-active {
        scale: 1.5;
        opacity: 1;      
      }
    }
  }
  
  .slide-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const SlideContentWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
  max-width: 800px;

  ${media('>tablet')} {
    padding: 0 120px;
    max-width: 1000px;
  }

  ${media('>desktop')} {
    max-width: 1200px;
  }

`;

const SlideContent = styled.div`
  max-width: 866px;

  .badge {
    max-width: 100%;
    width: 160px;
    margin-bottom: 10px;
    ${media('>phone')} {
      width: auto;
    }
    ${media('>desktop')} {
      margin-bottom: 20px;
    }
  }

  .button {
    margin-top: 30px;
    ${media('>desktop')} {
      margin-top: 40px;
    }
  }
`;

const SlideTitle = styled.h1`
  font-family: var(--light-title-font-family);
  color: var(--colour-white);

  font-size: 45px;
  font-weight: 300;
  line-height: 1;
  margin: 0;
  letter-spacing: -1px;

  @media (min-height: 800px) {
    font-size: 60px;
  }

  ${media('>tablet')} {
    font-size: 60px;
  }

  ${media('>desktop')} {
    font-size: 80px;
  }

  ${media('>largeDesktop')} {
    font-size: 100px;
  }

`;

const SlideDescription = styled.p`
  color: var(--colour-white);
  max-width: 616px;
  margin-top: 26px;
  margin-bottom: 0;

  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;

  ${media('>tablet')} {
    font-size: 18px;
  }

  ${media('>desktop')} {
    margin-top: 32px;
    font-size: 20px;
  }
`;

const Hero = ({ slides }: HeroProps): React.ReactElement => {
  const [activeSlide, setActiveSlide] = useState(0);
  return (
    <HeroSection>
      <SwiperContainer>
        <Swiper
          modules={[EffectFade, Pagination, A11y, Autoplay]}
          slidesPerView={1}
          speed={500}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false
          }}
          effect="fade"
          pagination={{
            el: '.custom-swiper-pagination-hero',
            clickable: true
          }}
          onActiveIndexChange={(swiperCore) => {setActiveSlide(swiperCore.realIndex)}}
        >
          {slides.map((slide, i) => (
            <SwiperSlide key={i}>
              <GatsbyImage
                image={slide.image.asset.gatsbyImageData}
                alt={slide.title}
                objectFit="cover"
                className="slide-image"
              ></GatsbyImage>
              <SlideContentWrapper>
                <SlideContent>
                  {slide.badge && 
                    <img className="badge" src={slide.badge.asset.url}/>
                  }
                  <SlideTitle>{slide.title}</SlideTitle>
                  <SlideDescription>{slide.description}</SlideDescription>
                  {slide.ctaLink && (
                    <ButtonNew url={getUrlFromVersatileLink(slide.ctaLink)} className="button">
                      {slide.ctaLink.text}
                    </ButtonNew>
                  )}
                </SlideContent>
              </SlideContentWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className='swiper-pagination-container'>
          <span className="active-slide">
            {activeSlide + 1} / {slides.length}
          </span>
          <div className="custom-swiper-pagination-hero"></div>
        </div>
      </SwiperContainer>
    </HeroSection>
  );
};

export default Hero;

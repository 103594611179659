import React from 'react';
import { graphql } from 'gatsby';
import '../fragments';

import PageSEO from '../components/PageSEO';

import { LocalizedSEO } from '../fragments';
import LayoutContainer from '../components//LayoutContainer';
import Hero from '../components/ui/Hero';
import PageTitle from '../components/ui/PageTitle';
import { Modules } from '../graphql-fragments/modules';
import { HeaderBackground } from '../components/ui/Header';
import { NavigationMenu } from '../graphql-fragments/navigationMenu';
import ModulesContent from '../components/ModulesContent';
import Breadcrumbs from '../components/ui/Breadcrumbs';
import { getParentMenuEntry, getReferenceUrl } from '../utils/utils';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { CtaLink } from '../graphql-fragments/ctaLink';

export const query = graphql`
  query GetPageById($id: String!) {
    sanityPage(id: { eq: $id }) {
      title
      slug {
        current
      }
      headerBackground
      hero {
        slides {
          image {
            asset {
              url
              gatsbyImageData(width: 2560, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
          badge {
            asset {
              url
            }
          }
          title
          description
          ctaLink {
            ...CtaLink
          }
        }
      }
      modules {
        ...Modules
      }
      showLetsTalkBanner
      seo {
        title
        description
        image {
          asset {
            url
          }
        }
      }
    }
    sanityWebsite {
      navigationMenu {
        ...NavigationMenu
      }
    }
  }
`;

interface PageProps {
  data: {
    sanityPage: {
      title: string;
      slug: {
        current: string;
      };
      headerBackground: HeaderBackground;
      hero?: {
        slides: Array<{
          image: {
            asset: {
              url: string;
              gatsbyImageData: IGatsbyImageData;
            };
          };
          badge: {
            asset: {
              url: string;
            };
          };
          title: string;
          description: string;
          ctaLinks?: Array<CtaLink>;
        }>;
      };
      modules: Modules;
      showLetsTalkBanner?: boolean;
      seo: LocalizedSEO;
    };
    sanityWebsite: {
      navigationMenu: NavigationMenu;
    };
  };
}

const Page = ({ data }: PageProps): React.ReactElement => {
  const {
    sanityPage: page,
    sanityWebsite: { navigationMenu },
  } = data;

  const pageUrl = getReferenceUrl('SanityPage', page.slug.current);
  const parentMenuEntry = getParentMenuEntry(pageUrl, navigationMenu);

  return (
    <LayoutContainer
      headerBackground={page.slug.current === '/' ? 'transparent' : page.headerBackground}
      pageTitle={page.title}
      showLetsTalkBanner={page.showLetsTalkBanner}
    >
      {parentMenuEntry && (
        <Breadcrumbs
          links={[
            { title: 'Home', url: '/' },
            { title: parentMenuEntry.title, url: parentMenuEntry.url },
            { title: page.title, url: pageUrl },
          ]}
        />
      )}
      <PageSEO
        defaultTitle={
          page.slug.current === '/' ? '' : data.sanityPage.seo?.title || data.sanityPage.title
        }
        defaultDescription={data.sanityPage.seo?.description}
        defaultImageUrl={data.sanityPage.seo?.image?.asset?.url}
      />
      {page.headerBackground && (
        <PageTitle background={page.headerBackground}>{page.title}</PageTitle>
      )}
      {page.hero && <Hero {...page.hero}></Hero>}
      <ModulesContent previousModuleBgColor={null} modules={page.modules} />
    </LayoutContainer>
  );
};

export default Page;

import React from 'react'
import styled, { css } from 'styled-components'
import media from 'css-in-js-media'
import backgroundImage from '../../images/UI/header-background-image-bright.jpeg';
import mobileBackgroundImage from '../../images/UI/mobileBrandHeader.png';

const PageTitleRoot = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 368px;

    ${({ background }) => {
        if (background === 'transparent') {
          return css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 99;
            background-color: 'transparent';
          `;
        }
        if (background === 'brandImage') {
          return css`
            height: 368px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${mobileBackgroundImage}) 50%, lightgray 50% / cover no-repeat;

            ${media('>tablet')} {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${backgroundImage}) 50%, lightgray 50% / cover no-repeat;
            }
          `;
        }
        if (background === 'black') {
          return css`
            background-color: var(--colour-black);
          `;
        }
        if (background === 'blackWithBrandStrip') {
          return css`
            background-color: var(--colour-black);
          `;
        }
      }};    
`;

const Title = styled.h1`
color: var(--colour-white);
text-align: center;
font-family: var(--light-title-font-family);
font-size: 40px;
font-style: normal;
font-weight: 300;
line-height: 40px; /* 100% */
transform: translateY(50%);

${media('>tablet')} {
    font-size: 60px;
    line-height: 70px;
}
`;

const PageTitle = ({ children, background }) => {
    return (
        <PageTitleRoot background={background}>
            <Title>{children}</Title>
        </PageTitleRoot>
    )
}

export default PageTitle